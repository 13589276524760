@media only screen and (max-width: 767px) {
    /* Default CSS */
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .ptb-70 {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-45 {
        padding-top: 30px;
    }
    .ml-20 {
        margin-left: 10px;
    }
    .ml-25 {
        margin-left: 0;
    }
    .mr-20 {
        margin-right: 0;
    }
    .mt-30 {
        margin-top: 0;
    }
    .pe-0 {
        padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    }
    .default-btn {
        padding: 8px 18px;
    }
    .section-title span {
        font-size: 14px;
    }
    .section-title h2 {
        font-size: 26px;
        margin-top: 0;
    }
    .section-title p {
        margin-left: auto;
        margin-right: auto;
    }
    h3 {
        font-size: 18px;
    }
    /* Default CSS End */
    /* Top Header CSS */
    .top-left-side {
        text-align: center;
    }
    .top-left-side .top-header-text {
        margin-left: auto;
        margin-right: auto;
    }
    .top-left-side .top-header-text::before {
        display: none;
    }
    .top-left-side .top-header-text p {
        font-size: 15px;
        margin-bottom: 5px;
    }
    .top-header-right {
        float: right;
        text-align: center;
        margin-top: 5px;
    }
    .top-header-right .language-list {
        margin-left: 0;
        margin-right: 0;
        margin-top: 5px;
    }
    .top-header-right .language-list::before {
        display: none;
    }
    .top-head-left {
        margin-top: 3px;
        float: none;
        text-align: center;
    }
    /* Top Header CSS End */
    /* Navbar Area CSS  */
    .side-nav-responsive {
        display: block;
    }
    .d-display {
        display: none;
    }
    .mobile-nav .logo img {
        max-width: 200px;
        height: auto;
    }
    .search-overlay .search-form {
        max-width: 100%;
        width: 100%;
    }
    .search-overlay .search-form form .input-search {
        height: 50px;
        padding: 0 0 0 15px;
    }
    .search-overlay .search-form form button {
        position: absolute;
        right: 5px;
        top: 5px;
        width: 40px;
        height: 40px;
        font-size: 18px;
        line-height: 35px;
    }
    /* Navbar Area CSS End */
    /* Main Banner Area CSS */
    .banner-slider-area {
        position: relative;
    }
    .banner-slider-area .owl-dots {
        display: inline-block;
        right: 0;
        left: 0;
        top: auto;
        bottom: 55px;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
    }
    .banner-slider-area .owl-dots .owl-dot span {
        margin: 7px 7px;
        width: 30px !important;
        height: 30px !important;
    }
    .banner-slider-area .owl-dots .owl-dot.active span {
        width: 30px !important;
        height: 30px !important;
    }
    .banner-slider-area .owl-dots .owl-dot:hover span {
        width: 30px !important;
        height: 30px !important;
    }
    .banner-item-content {
        padding-top: 140px;
        padding-bottom: 130px;
    }
    .banner-item-content span {
        font-size: 15px;
        font-weight: 600;
        padding: 8px 15px;
    }
    .banner-item-content h1 {
        max-width: 100%;
        font-size: 35px;
        margin-top: 25px;
    }
    .banner-item-content p {
        font-size: 16px;
        margin-bottom: 25px;
    }
    .banner-item-ptb {
        padding-top: 130px;
        padding-bottom: 70px;
    }
    .banner-item-ptb h1 {
        margin-top: 0;
    }
    .banner-area::before {
        background-image: none;
        background-color: #000000;
        opacity: 0.6;
    }
    .banner-content {
        padding-top: 120px;
        margin-top: 0;
    }
    .banner-content h1 {
        font-size: 35px;
        max-width: 100%;
    }
    .banner-content p {
        font-size: 16px;
        max-width: 100%;
    }
    .banner-img {
        padding-top: 30px;
    }
    .banner-img .banner-img-shape {
        display: none;
    }
    .banner-sub-slider {
        margin-top: 30px;
        padding-bottom: 70px;
    }
    .banner-sub-slider .banner-sub-item {
        padding: 15px 10px 15px 135px;
        max-width: 300px;
    }
    .banner-sub-slider .banner-sub-item img {
        max-width: 107px;
    }
    .banner-sub-slider .banner-sub-item .content {
        padding: 3px 0 7px;
    }
    .banner-sub-slider .banner-sub-item .content h3 {
        font-size: 35px;
    }
    .banner-sub-slider .banner-sub-item .content span {
        font-size: 16px;
    }
    .banner-four-area {
        padding: 130px 0 50px;
    }
    .banner-four-content {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
    .banner-four-content span {
        font-size: 13px;
        margin-bottom: 10px;
    }
    .banner-four-content h1 {
        font-size: 35px;
        margin-bottom: 15px;
    }
    .banner-four-content p {
        font-size: 15px;
        margin-bottom: 20px;
    }
    .banner-four-content .banner-btn .default-btn {
        display: inline-block;
    }
    .banner-four-content .banner-btn .play-btn {
        padding-left: 55px;
        position: relative;
        z-index: 1;
        top: 0;
    }
    .banner-four-content .banner-btn .play-btn i {
        position: absolute;
        top: -3px;
        left: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
    }
    .banner-four-content .banner-btn .play-btn h3 {
        margin-bottom: 5px;
        font-size: 14px;
    }
    .banner-four-content .banner-btn .play-btn span {
        font-size: 12px;
        -webkit-transition: 0.7s;
        transition: 0.7s;
        margin-bottom: 0;
    }
    .banner-four-img {
        margin-bottom: 0;
        text-align: center;
    }
    .banner-four-img img {
        text-align: center;
    }
    .banner-five-area {
        padding: 130px 0 50px;
    }
    .banner-five-content {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
    .banner-five-content span {
        font-size: 13px;
        margin-bottom: 10px;
    }
    .banner-five-content h1 {
        font-size: 35px;
        margin-bottom: 15px;
    }
    .banner-five-content p {
        font-size: 15px;
        margin-bottom: 20px;
    }
    .banner-five-img {
        -webkit-animation: none;
        animation: none;
    }
    /* Main Banner Area CSS End */
    /* Banner Bottom Area CSS */
    .banner-bottom-area {
        position: inherit;
        margin-top: 50px;
    }
    .banner-bottom-card {
        position: relative;
        padding: 20px 15px;
        text-align: center;
    }
    .banner-bottom-card i {
        position: inherit;
        top: 0;
        left: 0;
        right: 0;
        margin-bottom: 10px;
    }
    /* Banner Bottom Area CSS End */
    .inner-banner .inner-title {
        padding-top: 120px;
        padding-bottom: 60px;
    }
    .inner-banner .inner-title h3 {
        font-size: 30px;
    }
    .inner-banner .inner-shape {
        display: none;
    }
    /* About Area CSS */
    .about-img {
        margin-top: 30px;
    }
    .about-img::before {
        display: none;
    }
    .about-img .sub-content {
        padding: 15px 10px 15px 145px;
        max-width: 295px;
    }
    .about-img .sub-content img {
        max-width: 107px;
    }
    .about-img .sub-content .content {
        padding: 5px 0;
    }
    .about-img .sub-content .content h3 {
        font-size: 35px;
    }
    .about-img .sub-content .content span {
        font-size: 16px;
    }
    .about-content .about-list {
        margin: 20px auto 10px;
    }
    .about-content .section-title h2 {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }
    .about-content .section-title p {
        max-width: 100%;
    }
    .about-content .about-list-2 {
        margin: 0 auto !important;
    }
    .about-content .about-content-card {
        margin-top: 20px;
    }
    .about-content .about-content-card i {
        font-size: 40px;
    }
    .about-content .about-content-card h3 {
        font-size: 18px;
    }
    .about-content .about-content-card p {
        font-size: 14px;
    }
    .about-content-2 .about-card {
        margin-top: 20px;
    }
    .about-content-2 .about-card .content {
        padding-top: 0;
        padding-left: 0;
        margin-bottom: 0;
    }
    .about-content-2 .about-card .content i {
        font-size: 40px;
        position: inherit;
        top: 0;
        left: 0;
        right: 0;
    }
    .about-content-2 .about-card .content h3 {
        margin-top: 15px;
        font-size: 18px;
        margin-bottom: 0;
    }
    .about-content-2 .about-card p {
        margin-top: 15px;
        margin-bottom: 0;
        color: #ffffff;
        font-size: 14px;
    }
    .about-play {
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
    }
    .about-play img {
        border-radius: 10px;
    }
    .about-play .about-play-content {
        margin-top: 30px;
        position: inherit;
        background-color: #0071dc;
        border-radius: 10px;
        padding: 40px 30px 60px;
        max-width: 100%;
        text-align: center;
    }
    .about-play .about-play-content::before {
        display: none;
    }
    .about-play .about-play-content h2 {
        font-size: 24px;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 8px;
    }
    .about-play .about-play-content .play-on-area {
        position: inherit;
        top: 15px;
        bottom: auto;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }
    .about-play .about-play-content .play-on-area .play-on {
        width: 60px;
        height: 60px;
        font-size: 30px;
        line-height: 65px;
    }
    .about-img-4 {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .about-content-3 {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .about-content-3 h3 {
        font-size: 18px;
    }
    /* About Area CSS End */
    .choose-content {
        text-align: center;
    }
    .choose-content .choose-content-card .content {
        padding-top: 10px;
        padding-left: 0;
    }
    .choose-content .choose-content-card .content i {
        position: inherit;
        top: 0;
        font-size: 45px;
    }
    .choose-content .choose-content-card .content h3 {
        margin-top: 13px;
        margin-bottom: 10px;
        font-size: 18px;
    }
    .choose-content .choose-content-card p {
        font-size: 14px;
    }
    .choose-content-two .section-title p {
        padding-top: 10px;
    }
    .choose-content-two .choose-content-card {
        padding-left: 0;
        margin-bottom: 30px;
    }
    .choose-content-two .choose-content-card i {
        position: inherit;
        margin-bottom: 15px;
        width: 65px;
        height: 65px;
        line-height: 65px;
        font-size: 30px;
        display: inline-block;
    }
    .choose-content-two .choose-content-card h3 {
        margin-bottom: 10px;
    }
    .choose-content-two .choose-content-card p {
        margin-bottom: 0;
        max-width: 100%;
    }
    .choose-img-two {
        padding-right: 0;
        padding-bottom: 0;
    }
    .choose-img-two::before {
        display: none;
    }
    .counter-content h1 {
        font-size: 65px;
    }
    .counter-content h3 {
        font-size: 18px;
    }
    .contact-info {
        padding: 30px 15px 10px;
    }
    .contact-info ul li {
        margin-bottom: 20px;
    }
    /* Security Area CSS */
    .security-card {
        padding: 30px 20px;
    }
    .security-card i {
        width: 65px;
        height: 65px;
        line-height: 65px;
        font-size: 28px;
    }
    /* Security Area CSS End */
    .technology-card i {
        font-size: 40px;
    }
    .technology-card h3 {
        font-size: 18px;
    }
    .technology-area-four .section-title {
        margin-bottom: 20px;
    }
    .technology-area-four .section-title p {
        padding-top: 12px;
    }
    .technology-item {
        padding: 12px 10px 10px;
    }
    .technology-item i {
        font-size: 30px;
    }
    .technology-item h3 {
        margin-top: 15px;
        font-size: 16px;
    }
    .technology-play-area {
        padding: 100px 0;
        margin-left: 0;
    }
    .technology-play-area .play-btn i {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 30px;
    }
    /* Service Area CSS */
    .services-card {
        text-align: center;
    }
    .service-shape {
        display: none;
    }
    .services-item {
        text-align: center;
    }
    .services-item .content {
        width: 95%;
        padding: 95px 20px 20px;
    }
    .services-item .content i {
        display: inherit;
        top: 25px;
        left: 0;
        right: 0;
        text-align: center;
    }
    .services-item .content h3 {
        font-size: 18px;
    }
    .service-article .service-article-content .service-article-rs {
        margin: 0 0 8px;
    }
    .services-left .section-title {
        margin-bottom: 15px;
    }
    .services-left .section-title p {
        padding-top: 12px;
    }
    /* Service Area CSS End */
    .case-study-item .content ul li {
        font-size: 13px;
    }
    .case-study-item .content ul li::before {
        width: 1.5px;
        height: 13px;
    }
    /* Blog Area CSS */
    .blog-article .blog-article-title ul li {
        margin-right: 10px;
        margin-bottom: 5px;
    }
    .blog-article .article-content .blockquote {
        margin-left: 0;
        padding: 20px 30px;
    }
    .blog-article .article-content .blockquote i {
        display: none;
    }
    .blog-article .blog-article-share .blog-tag ul li {
        padding: 5px 0;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .blog-article .blog-article-share .blog-tag ul li a {
        padding: 6px 12px;
    }
    .blog-article .blog-article-share .social-icon {
        float: none;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .blog-article .comments-wrap .comment-list li {
        padding-left: 0;
    }
    .blog-article .comments-wrap .comment-list li img {
        position: inherit;
        top: 0;
        left: 0;
        margin-bottom: 10px;
    }
    .blog-article .comments-wrap .comment-list li h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .blog-article .comments-wrap .comment-list li p {
        margin-bottom: 10px;
    }
    .blog-article .comments-wrap .comment-list li a {
        color: #0071dc;
        font-weight: 600;
        position: inherit;
        top: 0;
        left: 0;
    }
    .blog-style-card .content ul li {
        margin-right: 5px;
    }
    .blog-style-card .content ul li i {
        margin-right: 5px;
    }
    .blog-card .blog-img .blog-tag {
        padding: 10px 7px;
    }
    .blog-card .blog-img .blog-tag h3 {
        font-size: 14px;
    }
    .blog-card .content ul li {
        font-size: 14px;
    }
    .blog-card .content ul li i {
        font-size: 15px;
    }
    .blog-card .content h3 {
        font-size: 21px;
    }
    .blog-card .content .read-btn {
        font-size: 14px;
    }
    /* Blog Area CSS End */
    /* Work Process Area CSS */
    .work-process-card {
        text-align: center;
    }
    .work-process-left {
        padding: 50px 25px;
        text-align: center;
    }
    .work-process-area-two {
        position: relative;
    }
    .work-process-area-two .col-lg-3:nth-child(2) .work-process-card {
        margin-top: 0;
    }
    .work-process-area-two .col-lg-3:nth-child(4) .work-process-card {
        margin-top: 0;
    }
    .work-shape {
        display: none;
    }
    .work-process-card-two {
        padding: 20px 15px 25px;
        margin-bottom: 30px;
    }
    .work-process-card-two .number-title {
        font-size: 26px;
        margin-bottom: 5px;
    }
    .work-process-card-two h3 {
        margin-bottom: 10px;
    }
    .work-process-card-three {
        padding: 20px 15px 25px;
    }
    .work-process-card-three::before {
        width: 75px;
        height: 65px;
    }
    .work-process-card-three .number-title {
        font-size: 26px;
        margin-bottom: 5px;
    }
    .work-process-card-three h3 {
        margin-bottom: 10px;
    }
    .work-process-card-three i {
        font-size: 25px;
    }
    .work-process-right {
        padding: 50px 25px;
    }
    .work-process-right .section-title {
        margin-bottom: 20px;
    }
    /* Work Process Area CSS End */
    .build-content {
        text-align: center;
    }
    .build-btn-area {
        float: none;
        text-align: center;
    }
    .case-article .case-article-content .case-article-rs {
        margin: 0 0 8px;
    }
    /* Build Area CSS */
    .build-btn-area {
        float: none;
        margin-top: 20px;
    }
    .build-item .content ul li {
        font-size: 13px;
    }
    .build-play-img img {
        border-radius: 10px;
    }
    .build-play-img .play-area-content {
        margin-top: 30px;
        position: inherit;
        background-color: #0071dc;
        border-radius: 10px;
        padding: 40px 30px;
        max-width: 100%;
        text-align: center;
    }
    .build-play-img .play-area-content::before {
        display: none;
    }
    .build-play-img .play-area-content h2 {
        font-size: 24px;
        margin-left: auto;
        margin-right: auto;
    }
    .build-play-img .play-area {
        position: absolute;
        z-index: 1;
        top: 18%;
        bottom: auto;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }
    .build-play-img .play-area .play-on {
        width: 60px;
        height: 60px;
        font-size: 30px;
        line-height: 63px;
    }
    .build-text {
        margin-bottom: 25px;
        margin-top: 20px;
        text-align: center;
    }
    .play-btn-area {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    /* Build Area CSS End */
    /* Counter Area CSS */
    .counter-shape {
        display: none;
    }
    .counter-content h1 {
        font-size: 85px;
    }
    .counter-another-content {
        margin-bottom: 20px;
        padding-left: 0;
        position: relative;
        text-align: center;
    }
    .counter-another-content i {
        font-size: 40px;
        position: inherit;
        right: 0;
    }
    .counter-another-content h3 {
        font-size: 26px;
        margin-top: 15px;
        margin-bottom: 5px;
    }
    .counter-another-content span {
        font-size: 15px;
    }
    /* Counter Area CSS End */
    .client-circle {
        display: none;
    }
    .clients-content {
        padding: 30px 15px;
    }
    .clients-area .owl-nav {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .clients-area .owl-nav .owl-prev {
        top: auto;
        position: inherit;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        left: 0;
        font-size: 20px !important;
        bottom: -30px;
        width: 35px;
        height: 35px;
        border-radius: 50px;
        line-height: 38px !important;
    }
    .clients-area .owl-nav .owl-next {
        top: auto;
        position: inherit;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        right: 0;
        font-size: 20px !important;
        bottom: -30px;
        width: 35px;
        height: 35px;
        border-radius: 50px;
        line-height: 38px !important;
    }
    .clients-area-three {
        padding-bottom: 45px;
    }
    .clients-area-three .owl-nav {
        margin-top: 0;
    }
    .clients-area-three .owl-nav .owl-prev {
        position: inherit;
        bottom: 0;
        left: 0;
        font-size: 20px !important;
        width: 32px;
        height: 32px;
        line-height: 34px !important;
    }
    .clients-area-three .owl-nav .owl-next {
        position: inherit;
        bottom: 90px;
        font-size: 20px !important;
        width: 32px;
        height: 32px;
        line-height: 34px !important;
    }
    .clients-slider-img {
        margin-bottom: 30px;
    }
    .clients-slider-img img {
        text-align: center;
    }
    .clients-slider-img .clients-slider-circle {
        height: 60%;
        left: 0;
        -webkit-animation: none;
        animation: none;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#0071dc), to(#070b3b));
        background-image: linear-gradient(#0071dc, #070b3b);
    }
    .clients-slider-content {
        margin-bottom: 20px;
        text-align: center;
    }
    .clients-slider-content i {
        width: 50px;
        height: 50px;
        line-height: 52px;
        font-size: 26px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }
    .clients-slider-content p {
        margin-bottom: 20px;
        font-size: 16px;
    }
    .clients-slider-content h3 {
        margin-bottom: 5px;
    }
    .blog-card .content {
        padding: 20px;
    }
    /* Error Area CSS */
    .error-area {
        height: 100vh;
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .error-area .error-content h3 {
        margin-bottom: 10px;
        font-size: 25px;
    }
    .error-area .error-content p {
        font-size: 16px;
        padding: 0 15px;
    }
    /* Error Area CSS End */
    /* Coming Soon */
    .coming-soon-area .coming-soon-content {
        padding-top: 30px;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .coming-soon-area .coming-soon-content h1 {
        font-size: 30px;
        line-height: 1.1;
        margin-top: 0;
    }
    .coming-soon-area .coming-soon-content p {
        font-size: 14px;
        margin-top: 15px;
    }
    .coming-soon-area .coming-soon-content #timer {
        margin-top: 30px;
    }
    .coming-soon-area .coming-soon-content #timer div {
        font-size: 30px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 15px;
    }
    .coming-soon-area .coming-soon-content #timer div span {
        font-size: 14px;
        margin-top: -5px;
    }
    .coming-soon-area .coming-soon-content #timer div::before {
        display: none;
    }
    .coming-soon-area .coming-soon-content .newsletter-form {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .coming-soon-area .coming-soon-content .newsletter-form .input-newsletter {
        height: 55px;
        padding-left: 13px;
        font-size: 15px;
    }
    .coming-soon-area .coming-soon-content .newsletter-form button {
        position: relative;
        font-size: 14px;
        margin-top: 15px;
        width: 100%;
        height: 50px;
    }
    /* Coming Soon End */
    /* Footer Area */
    .footer-widget h3 {
        margin-bottom: 20px;
    }
    .footer-widget .footer-call-content {
        max-width: 100%;
    }
    .footer-widget .footer-call-content h3 {
        font-size: 20px;
    }
    .footer-widget .footer-call-content i {
        top: 15px;
    }
    .footer-widget p {
        max-width: 100%;
    }
    /* Footer Area End */
    .banner-six::before {
        background-image: none;
        background-color: #070b3b;
        opacity: 0.7;
    }
    .banner-six-content {
        padding-top: 125px;
        padding-bottom: 50px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .banner-six-content h1 {
        font-size: 30px;
        margin-bottom: 15px;
    }
    .banner-six-content p {
        font-size: 15px;
        margin-bottom: 20px;
    }
    .banner-six-shape {
        display: none;
    }
    .about-img-5::before {
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        height: 70%;
        -webkit-animation: 0;
        animation: 0;
    }
    .team-item .content .social-link li a {
        width: 28px;
        height: 28.5px;
        line-height: 30px;
    }
    .case-study-item2 .icon-services {
        font-size: 45px;
        margin-bottom: 10px;
    }
    .case-study-item2 .more-btn {
        width: 30px;
        height: 30px;
        line-height: 39px;
        font-size: 16px;
        border-top-left-radius: 14px;
        border-bottom-right-radius: 0px;
    }
    .case-study-item2 h3 {
        margin-bottom: 10px;
    }
    .footer-widget .social-link ul li a {
        width: 28px;
        height: 28px;
        line-height: 32px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 575px) {
    .banner-item-content {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .banner-four-area .container-fluid,
    .banner-five-area .container-fluid,
    .about-area .container-fluid {
        width: 540px;
    }
    .build-play-img .play-area {
        top: 27%;
    }
    .blog-article .blog-article-title ul li {
        margin-right: 10px;
        margin-bottom: 5px;
    }
    .blog-article .article-content .blockquote {
        margin-left: 0;
        padding: 20px 30px;
    }
    .blog-article .article-content .blockquote i {
        display: none;
    }
    .blog-article .blog-article-share .blog-tag ul li {
        padding: 5px 0;
        margin-top: 0;
        margin-bottom: 0;
    }
    .blog-article .blog-article-share .social-icon {
        float: right;
        margin-bottom: 0;
    }
    /* Footer Area */
    .footer-widget .footer-call-content {
        padding: 20px 70px 20px 15px;
    }
    .footer-widget .footer-call-content h3 {
        font-size: 16px;
    }
    .footer-widget .footer-call-content i {
        top: 22px;
        right: 15px;
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 24px;
    }
    .footer-widget .footer-call-content span a {
        font-size: 13px;
    }
    .footer-widget .footer-blog li h3 {
        font-size: 15px;
    }
    /* Footer Area End */
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    /* Default CSS */
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .ptb-70 {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-45 {
        padding-top: 30px;
    }
    .pe-0 {
        padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    }
    .mt-30 {
        margin-top: 0;
    }
    .ml-25 {
        margin-left: 0;
    }
    .ml-20 {
        margin-left: 10px;
    }
    .mr-20 {
        margin-right: 0;
    }
    .default-btn {
        padding: 8px 18px;
    }
    .section-title span {
        font-size: 14px;
        list-style: 0;
        padding: 0;
        margin: 0;
    }
    .section-title h2 {
        font-size: 26px;
        margin-top: 0;
    }
    .section-title p {
        margin-left: auto;
        margin-right: auto;
    }
    h3 {
        font-size: 21px;
    }
    .banner-four-area .container-fluid,
    .banner-five-area .container-fluid,
    .about-area .container-fluid {
        width: 720px;
    }
    /* Default CSS End */
    .about-play {
        margin-bottom: 30px;
    }
    .work-process-left {
        text-align: center;
    }
    .side-nav-responsive {
        display: block;
    }
    .d-display {
        display: none;
    }
    .mobile-nav .logo img {
        max-width: 140px;
        height: auto;
    }
    .top-header-right .language-list {
        margin-left: -15px;
    }
    .about-content-2 .section-title h2,
    .about-content-2 .section-title p {
        max-width: 100%;
    }
    /* Navbar Area CSS  */
    .side-nav-responsive {
        display: block;
    }
    .d-display {
        display: none;
    }
    .mobile-nav .logo img {
        max-width: 140px;
        height: auto;
    }
    /* Navbar Area CSS End */
    /* Main Banner Area CSS */
    .banner-slider-area {
        position: relative;
    }
    .banner-slider-area .owl-dots {
        display: inline-block;
        right: 0;
        left: 0;
        top: auto;
        bottom: 55px;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
    }
    .banner-item-content {
        padding-top: 220px;
        padding-bottom: 220px;
        text-align: center;
    }
    .banner-item-content span {
        font-size: 15px;
        font-weight: 600;
        padding: 8px 15px;
    }
    .banner-item-content h1 {
        font-size: 35px;
        max-width: 100%;
    }
    .banner-item-content p {
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
    }
    .banner-item-ptb {
        padding-top: 250px;
        padding-bottom: 190px;
    }
    .banner-content {
        padding-top: 120px;
        margin-top: 0;
        text-align: center;
    }
    .banner-content h1 {
        font-size: 35px;
        margin-left: auto;
        margin-right: auto;
    }
    .banner-content p {
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
    }
    .banner-area::before {
        background-image: none;
        background-color: #000000;
        opacity: 0.6;
    }
    .banner-img {
        padding-top: 30px;
    }
    .banner-img .banner-img-shape {
        display: none;
    }
    .banner-sub-slider {
        padding-bottom: 70px;
    }
    .banner-four-area {
        padding: 130px 0 50px;
    }
    .banner-four-content {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
    .banner-four-content span {
        font-size: 13px;
        margin-bottom: 10px;
    }
    .banner-four-content h1 {
        font-size: 35px;
        margin-bottom: 15px;
    }
    .banner-four-content p {
        font-size: 15px;
        margin-bottom: 20px;
    }
    .banner-four-content .banner-btn .default-btn {
        display: inline-block;
    }
    .banner-four-content .banner-btn .play-btn {
        padding-left: 55px;
        position: relative;
        z-index: 1;
        top: 0;
    }
    .banner-four-content .banner-btn .play-btn i {
        position: absolute;
        top: -3px;
        left: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
    }
    .banner-four-content .banner-btn .play-btn h3 {
        margin-bottom: 5px;
        font-size: 14px;
    }
    .banner-four-content .banner-btn .play-btn span {
        font-size: 12px;
        -webkit-transition: 0.7s;
        transition: 0.7s;
        margin-bottom: 0;
    }
    .banner-four-img {
        margin-bottom: 0;
        text-align: center;
    }
    .banner-four-img img {
        text-align: center;
    }
    .banner-five-area {
        padding: 130px 0 50px;
    }
    .banner-five-content {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
    .banner-five-content span {
        font-size: 13px;
        margin-bottom: 10px;
    }
    .banner-five-content h1 {
        font-size: 35px;
        margin-bottom: 15px;
    }
    .banner-five-content p {
        font-size: 15px;
        margin-bottom: 20px;
    }
    .banner-five-img {
        -webkit-animation: none;
        animation: none;
    }
    /* Main Banner Area CSS End */
    /* Banner Bottom Area CSS */
    .banner-bottom-area {
        position: inherit;
        margin-top: 50px;
    }
    .banner-bottom-card {
        position: relative;
        padding: 20px 15px;
        text-align: center;
    }
    .banner-bottom-card i {
        position: inherit;
        top: 0;
        left: 0;
        right: 0;
        margin-bottom: 10px;
    }
    /* Banner Bottom Area CSS End */
    .inner-banner .inner-title h3 {
        font-size: 35px;
    }
    .about-img {
        margin-top: 30px;
    }
    .about-img::before {
        right: -15px;
    }
    .about-content {
        text-align: center;
    }
    .about-content .section-title h2 {
        margin-left: auto;
        margin-right: auto;
    }
    .about-content-2 .section-title p {
        margin-left: 0;
        margin-right: unset;
    }
    .about-img-4 {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .about-content-3 {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .about-content-3 h3 {
        font-size: 18px;
    }
    .about-content-3 .section-title p {
        margin-left: 0;
    }
    .services-card {
        padding: 30px 30px 25px;
    }
    .service-shape {
        display: none;
    }
    .services-item {
        text-align: center;
    }
    .services-item .content {
        width: 90%;
        padding: 20px 15px 20px 92px;
    }
    .services-item .content h3 {
        font-size: 20px;
    }
    .services-left .section-title {
        margin-bottom: 15px;
    }
    .services-left .section-title p {
        padding-top: 12px;
        margin-left: 0;
    }
    .choose-content .section-title p {
        margin-left: 0;
        margin-right: unset;
    }
    .contact-info {
        padding: 40px 20px 10px;
    }
    .work-process-card {
        padding: 30px 32px;
    }
    .work-process-left {
        padding: 50px 30px;
    }
    .work-process-left .section-title h2 {
        font-size: 25px;
    }
    .work-process-area-two {
        position: relative;
    }
    .work-process-area-two .col-lg-3:nth-child(2) .work-process-card {
        margin-top: 0;
    }
    .work-process-area-two .col-lg-3:nth-child(4) .work-process-card {
        margin-top: 0;
    }
    .work-shape {
        display: none;
    }
    .work-process-card-two {
        padding: 25px 20px 30px;
        margin-bottom: 30px;
    }
    .work-process-card-two .number-title {
        font-size: 26px;
        margin-bottom: 5px;
    }
    .work-process-card-two h3 {
        margin-bottom: 10px;
    }
    .work-process-right {
        padding: 50px 25px;
    }
    .work-process-right .section-title {
        margin-bottom: 20px;
    }
    .build-play-img .play-area-content {
        padding: 100px 70px 65px 30px;
        max-width: 330px;
    }
    .build-play-img .play-area-content::before {
        width: 91%;
        height: 88.7%;
    }
    .build-play-img .play-area-content h2 {
        font-size: 28px;
    }
    .play-area {
        bottom: 145px;
        left: 190px;
    }
    .technology-area-three .section-title p {
        margin-left: 0;
    }
    .technology-area-four .section-title p {
        margin-left: 0;
    }
    .technology-play-area {
        padding: 150px 0;
        margin-left: 0;
    }
    .technology-play-area .play-btn i {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 30px;
    }
    .counter-shape {
        display: none;
    }
    .counter-content h1 {
        font-size: 65px;
    }
    .counter-content h3 {
        font-size: 18px;
    }
    .choose-content-two .section-title {
        margin-bottom: 20px;
    }
    .choose-content-two .section-title p {
        margin-left: 0;
    }
    .choose-content-two .choose-content-card {
        padding-left: 78px;
        margin-bottom: 30px;
    }
    .choose-content-two .choose-content-card i {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 30px;
        display: inline-block;
    }
    .choose-content-two .choose-content-card h3 {
        margin-bottom: 10px;
    }
    .choose-content-two .choose-content-card p {
        margin-bottom: 0;
        max-width: 100%;
    }
    .counter-another-content {
        margin-bottom: 20px;
        padding-left: 0;
        position: relative;
        text-align: center;
    }
    .counter-another-content i {
        font-size: 45px;
        position: inherit;
        right: 0;
    }
    .counter-another-content h3 {
        font-size: 30px;
        margin-top: 15px;
        margin-bottom: 5px;
    }
    .counter-another-content span {
        font-size: 15px;
    }
    .client-circle {
        display: none;
    }
    .clients-area .owl-nav {
        margin-top: 0;
        margin-bottom: 55px;
    }
    .clients-area .owl-nav .owl-prev {
        top: auto;
        position: inherit;
        font-size: 20px !important;
        bottom: -30px;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        width: 35px;
        height: 35px;
        border-radius: 50px;
        line-height: 38px !important;
    }
    .clients-area .owl-nav .owl-next {
        top: auto;
        position: inherit;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        font-size: 20px !important;
        bottom: -30px;
        width: 35px;
        height: 35px;
        border-radius: 50px;
        line-height: 38px !important;
    }
    .clients-area-three {
        padding-bottom: 45px;
    }
    .clients-area-three .owl-nav {
        margin-top: 0;
    }
    .clients-area-three .owl-nav .owl-prev {
        position: inherit;
        bottom: 0;
        left: 0;
        font-size: 20px !important;
        width: 32px;
        height: 32px;
        line-height: 34px !important;
    }
    .clients-area-three .owl-nav .owl-next {
        position: inherit;
        bottom: 90px;
        font-size: 20px !important;
        width: 32px;
        height: 32px;
        line-height: 34px !important;
    }
    .clients-slider-img {
        margin-bottom: 30px;
    }
    .clients-slider-img img {
        text-align: center;
    }
    .clients-slider-img .clients-slider-circle {
        height: 60%;
        left: 0;
        -webkit-animation: none;
        animation: none;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#0071dc), to(#070b3b));
        background-image: linear-gradient(#0071dc, #070b3b);
    }
    .clients-slider-content {
        margin-bottom: 20px;
        text-align: center;
    }
    .clients-slider-content i {
        width: 50px;
        height: 50px;
        line-height: 52px;
        font-size: 26px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }
    .clients-slider-content p {
        margin-bottom: 20px;
        font-size: 16px;
    }
    .clients-slider-content h3 {
        margin-bottom: 5px;
    }
    /* Error Area CSS */
    .error-area {
        height: 100vh;
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .error-area .error-content h3 {
        margin-bottom: 10px;
        font-size: 25px;
    }
    .error-area .error-content p {
        font-size: 16px;
        padding: 0 15px;
    }
    /* Error Area CSS End */
    .banner-six::before {
        background-image: none;
        background-color: #070b3b;
        opacity: 0.7;
    }
    .banner-six-content {
        padding-top: 125px;
        padding-bottom: 50px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .banner-six-content h1 {
        font-size: 30px;
        margin-bottom: 15px;
    }
    .banner-six-content p {
        font-size: 15px;
        margin-bottom: 20px;
    }
    .banner-six-shape {
        display: none;
    }
    .about-img-5::before {
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        height: 70%;
        -webkit-animation: 0;
        animation: 0;
    }
    .team-item .content .social-link li a {
        width: 29px;
        height: 29.7px;
        line-height: 31px;
    }
    .case-study-item2 .icon-services {
        font-size: 45px;
        margin-bottom: 10px;
    }
    .case-study-item2 .more-btn {
        width: 30px;
        height: 30px;
        line-height: 39px;
        font-size: 16px;
        border-top-left-radius: 14px;
        border-bottom-right-radius: 0px;
    }
    .case-study-item2 h3 {
        margin-bottom: 10px;
    }
    .work-process-rightside .section-title p {
        margin-left: 0;
    }
    .footer-widget .social-link ul li a {
        width: 28px;
        height: 28px;
        line-height: 32px;
        font-size: 16px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .main-nav .navbar .navbar-brand img {
        max-width: 140px;
        height: auto;
    }
    .main-nav nav .navbar-nav .nav-item a {
        margin-left: 8px;
        margin-right: 8px;
    }
    .main-nav .nav-side .nav-side-item {
        margin-right: 10px;
    }
    .main-nav .nav-side .nav-side-item .search-side-widget {
        max-width: 190px;
    }
    .main-nav .nav-side .nav-side-item .get-btn .default-btn {
        padding: 9px 15px;
    }
    .top-left-side .top-header-social ul li a {
        width: 24px;
        height: 24px;
        line-height: 26px;
        font-size: 16px;
    }
    .top-left-side .top-header-text {
        margin-left: 25px;
    }
    .top-left-side .top-header-text::before {
        left: -16px;
        width: 1px;
        height: 20px;
    }
    .top-left-side .top-header-text p {
        font-size: 13px;
    }
    .top-header-right .top-contact {
        padding-left: 35px;
    }
    .top-header-right .top-contact i {
        font-size: 15px;
        width: 24px;
        height: 24px;
        line-height: 24px;
    }
    .top-header-right .top-contact h3 {
        font-size: 13px;
        color: #ffffff;
        margin-bottom: 0;
    }
    .top-header-right .language-list {
        margin-left: -15px;
        top: -5px;
    }
    .top-header-right .language-list .nice-select {
        font-size: 13px;
    }
    .top-header-right .language-list .nice-select::after {
        font-size: 15px;
    }
    .banner-slider-area .owl-dots {
        top: 40%;
    }
    .banner-item-content {
        max-width: 600px;
    }
    .banner-item-content h1 {
        font-size: 55px;
    }
    .banner-item-content p {
        font-size: 17px;
    }
    .banner-bottom-card {
        padding: 30px 20px 30px 100px;
    }
    .banner-bottom-card i {
        position: absolute;
        top: 30px;
        left: 20px;
        font-size: 40px;
        width: 65px;
        height: 65px;
        line-height: 65px;
    }
    .banner-bottom-card h3 {
        font-size: 20px;
    }
    .banner-content {
        margin-top: -20px;
    }
    .banner-content h1 {
        font-size: 44px;
    }
    .banner-sub-slider {
        margin-top: -50px;
    }
    .banner-sub-slider .banner-sub-item {
        padding: 15px 10px 15px 140px;
        width: 100%;
    }
    .banner-sub-slider .banner-sub-item img {
        max-width: 107px;
    }
    .banner-sub-slider .banner-sub-item h3 {
        font-size: 35px;
    }
    .banner-sub-slider .banner-sub-item span {
        font-size: 15px;
    }
    .banner-img .banner-img-shape {
        bottom: 70px;
    }
    .banner-img .banner-img-shape img {
        max-width: 130px;
    }
    .banner-content p {
        font-size: 16px;
    }
    .banner-four-content {
        max-width: 500px;
    }
    .banner-four-content span {
        font-size: 14px;
    }
    .banner-four-content h1 {
        font-size: 40px;
    }
    .banner-four-content p {
        font-size: 16px;
        margin-bottom: 25px;
    }
    .banner-five-content {
        max-width: 450px;
    }
    .banner-five-content span {
        font-size: 14px;
    }
    .banner-five-content h1 {
        font-size: 40px;
    }
    .banner-five-content p {
        font-size: 16px;
        margin-bottom: 25px;
    }
    .blog-card .content {
        padding: 30px 25px;
    }
    .about-content-2 .about-card .content h3 {
        font-size: 20px;
    }
    .about-img::before {
        right: -20px;
    }
    .choose-content .choose-content-card .content {
        padding-left: 60px;
    }
    .choose-content .choose-content-card .content i {
        font-size: 45px;
    }
    .choose-content .choose-content-card .content h3 {
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 15px;
    }
    .services-card {
        padding: 30px 20px 25px;
    }
    .services-card h3 {
        font-size: 20px;
    }
    .service-shape img {
        max-width: 280px;
    }
    .services-item .content {
        width: 95%;
        padding: 20px 15px 20px 92px;
    }
    .services-item .content h3 {
        font-size: 20px;
    }
    .call-contact p {
        font-size: 18px;
    }
    .work-process-left {
        padding: 170px 25px;
    }
    .work-process-right {
        padding: 145px 25px;
    }
    .work-process-card {
        padding: 30px 20px;
    }
    .work-process-card h3 {
        font-size: 20px;
    }
    .work-process-card-two {
        padding: 30px 23px;
    }
    .work-process-card-three {
        padding: 20px 15px 25px;
    }
    .work-process-card-three::before {
        width: 75px;
        height: 65px;
    }
    .work-process-card-three .number-title {
        font-size: 26px;
        margin-bottom: 5px;
    }
    .work-process-card-three h3 {
        margin-bottom: 10px;
    }
    .work-process-card-three i {
        font-size: 25px;
    }
    .work-shape img {
        max-width: 975px;
    }
    .counter-shape .shape1 img {
        max-width: 160px;
    }
    .counter-shape .shape2 img {
        max-width: 160px;
    }
    .counter-another-content {
        padding-left: 85px;
    }
    .counter-another-content span {
        font-size: 15px;
    }
    .client-circle .client-circle-2 {
        top: 30%;
    }
    .clients-area .owl-nav .owl-prev {
        left: -20px;
        width: 35px;
        height: 35px;
        line-height: 35px !important;
    }
    .clients-area .owl-nav .owl-next {
        right: -20px;
        width: 35px;
        height: 35px;
        line-height: 35px !important;
    }
    .clients-area-three .owl-nav {
        margin-top: 0;
    }
    .clients-area-three .owl-nav .owl-prev {
        left: 475px;
        font-size: 25px !important;
        -webkit-transition: 0.7s;
        transition: 0.7s;
        width: 35px;
        height: 35px;
        line-height: 35px !important;
    }
    .clients-area-three .owl-nav .owl-next {
        left: 515px;
        font-size: 24px !important;
        -webkit-transition: 0.7s;
        transition: 0.7s;
        width: 35px;
        height: 35px;
        line-height: 35px !important;
    }
    .clients-slider-content {
        margin-bottom: 70px;
    }
    .clients-slider-content i {
        width: 60px;
        height: 60px;
        line-height: 62px;
        font-size: 30px;
        margin-bottom: 30px;
    }
    .clients-slider-content p {
        margin-bottom: 20px;
        font-size: 16px;
    }
    .clients-slider-content h3 {
        margin-bottom: 5px;
    }
    .call-shape .shape1 {
        left: 20px;
    }
    .call-shape .shape2 {
        right: 0;
    }
    .call-shape .shape2 img {
        max-width: 100px;
    }
    .call-shape .shape2-rs {
        right: 25px;
    }
    .call-shape .shape2-rs img {
        max-width: 120px;
    }
    .client-circle .client-circle-1 {
        left: 45px;
    }
    .client-circle .client-circle-3 {
        top: 87%;
        left: 2%;
    }
    .client-circle .client-circle-6 {
        top: 87%;
        right: 20px;
    }
    .footer-widget .footer-list li a {
        color: #ffffff;
        font-weight: 400;
        font-size: 14px;
    }
    .footer-widget .footer-list li a i {
        margin-right: 0;
        font-size: 16px;
    }
    .footer-widget .footer-call-content h3 {
        font-size: 20px;
    }
    .footer-widget .footer-call-content i {
        top: 15px;
    }
    .footer-widget .footer-blog li {
        padding-left: 80px;
    }
    .footer-widget .footer-blog li img {
        height: 65px;
        width: 65px;
    }
    .footer-widget .footer-blog li .content h3 {
        font-size: 13px;
    }
    .banner-six-content h1 {
        font-size: 50px;
    }
    .banner-six-shape img {
        height: 580px;
    }
    .case-study-item2 .icon-services {
        font-size: 45px;
        margin-bottom: 10px;
    }
    .case-study-item2 .more-btn {
        width: 30px;
        height: 30px;
        line-height: 39px;
        font-size: 16px;
        border-top-left-radius: 14px;
        border-bottom-right-radius: 0px;
    }
    .case-study-item2 h3 {
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 1800px) {
    .service-shape img {
        max-width: 500px;
    }
    .counter-shape .shape1 img {
        max-width: 100%;
    }
    .counter-shape .shape2 img {
        max-width: 100%;
    }
    .client-circle .client-circle-1 {
        left: 5%;
    }
    .client-circle .client-circle-2 {
        left: 15%;
    }
    .client-circle .client-circle-3 {
        left: 5%;
    }
    .client-circle .client-circle-4 {
        right: 30%;
    }
    .client-circle .client-circle-5 {
        right: 15%;
    }
    .client-circle .client-circle-6 {
        right: 5%;
    }
    .client-circle .client-circle-7 {
        right: 45%;
    }
    .banner-slider-area .owl-dots {
        right: 20%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .clients-area .owl-nav .owl-prev {
        left: -2%;
    }
    .clients-area .owl-nav .owl-next {
        right: -2%;
    }
    .about-img::before {
        right: -20px;
    }
    .call-shape .shape2 {
        right: -10px;
    }
}

@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
    .container-max {
        max-width: 1300px !important;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1400px) {
    .client-circle .client-circle-1 {
        left: 5%;
    }
    .client-circle .client-circle-2 {
        left: 15%;
        top: 17%;
    }
    .client-circle .client-circle-3 {
        left: 5%;
    }
    .client-circle .client-circle-4 {
        right: 30%;
    }
    .client-circle .client-circle-5 {
        right: 15%;
    }
    .client-circle .client-circle-6 {
        right: 5%;
    }
    .client-circle .client-circle-7 {
        right: 45%;
    }
}

@media only screen and (max-width: 991px) {
    .mobile-nav .logo .logo-two {
        display: none;
        height: auto;
    }
    .mobile-nav nav .navbar-nav {
        height: 300px;
        overflow-y: scroll;
        margin-top: 40px;
    }
    .side-bar-area {
        margin-left: 0;
    }
    .side-bar-widget {
        border: 1px solid #f3f3f3;
        padding: 20px;
        margin-bottom: 30px;
    }
    .side-bar-widget .side-bar-categories {
        margin-bottom: 0;
    }
    .side-bar-widget .side-bar-widget-tag {
        margin-bottom: 0;
    }
    .side-bar-widget .blog-gallery {
        margin-bottom: 0;
    }
    .side-bar-widget .widget-popular-post {
        margin-bottom: 0;
    }
    .call-shape {
        display: none;
    }
    .call-contact {
        text-align: center;
        padding: 50px 0 30px;
    }
    .call-contact h3 {
        font-size: 18px;
    }
    .call-contact .call-btn {
        font-size: 26px;
    }
    .call-contact p {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .contact-info ul li {
        display: inline-block;
        margin-right: 20px;
    }
    .contact-info ul li:last-child {
        margin-right: 0;
    }
    .blog-item .blog-img2 .blog-tag {
        padding: 11px 15px 6px;
    }
    .blog-item .blog-img2 .blog-tag h3 {
        font-size: 18px;
        margin-bottom: 0;
    }
    .blog-item .blog-img2 .blog-tag span {
        font-size: 13px;
    }

    .btn-login {
        margin-left: 20px;
    }
}
