// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$color-1: #376989;
$color-2: #5cbeea;

$color-3: #bf972f;
$color-4: #523a03;

$color-5: #ffaf30;
$color-6: #f8f8f8;
$color-7: #fff;

$color-8: #1d3a92;
$color-9: #070b3b;
