// Fonts
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Variables
@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "../OwlCarousel2-2.3.4/src/scss/owl.carousel.scss";
@import "../OwlCarousel2-2.3.4/src/scss/owl.theme.default.scss";
@import "../Magnific-Popup/src/css/main.scss";
@import "../jquery-nice-select-1.1.0/scss/nice-select.scss";
@import "mainmenu";
@import "template";
@import "responsive";
@import "theme-dark";
